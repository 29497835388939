.root {
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    flex-direction: row !important;
}

.label {
    color: #fff !important;
}

.label a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}